.hett{
    margin-left: 90%;
    border:1px solid rgb(119, 115, 115);
    border-radius: 5px;
    width:80px;
    height:30px;
}
.het{
  
width:700px;
margin-left: 25%;

}
.value{
    margin-top:20px;
    border:1px solid white;
    border-radius: 5px;
    background-color:red;
    color:white;
}
.aust{
 
    text-align: center;
border:1px solid rgb(23, 22, 22);
background-color: rgba(198, 16, 16, 0.896);
border-radius: 5px;
width:170px;
padding-top:10px;
margin-left: 80%;
margin-bottom:0px;

}
.auss{
    text-decoration: none;
    color: white;
}
.lt-headd{
    text-align: center;
}
.lt-section1{
    width:400px;
}
.inss{
    border:1px solid rgb(119, 115, 115);
    margin-left: 10%;
}