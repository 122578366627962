.full-card{
    width: 320px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border: 1px solid grey;
    background-color: white;
    margin: 20px;
    height:490px;
    border-radius: 10px;
}
.card{
    width:290px ;
    height: 190px;
    cursor: pointer;
    border:1px solid black;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);

    
}
.lt-head{
    background-color: rgb(191, 219, 217);
    width:100vw;
    height:100vh;
    overflow: hidden;
}
.imt{
   
}
.het{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  
}
.aus{
 
    text-align: center;
border:1px solid white;
background-color: rgba(198, 16, 16, 0.896);
border-radius: 5px;
width:100px;
margin-left: 80%;
}
.auss{
    text-decoration: none;
    color: white;
}
.cardsview{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.bottom-icons{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
}